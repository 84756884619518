import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Input, OnInit} from '@angular/core';
import {ItemStructure} from '../../dynamic-loader/models/item-structure.model';
import {PagesFacade} from '../../../pages/pages.facade';
import {Observable, of} from 'rxjs';
import {Router} from '@angular/router';
import {HttpResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {IPaginationParams} from '../../interfaces/IPaginationParams';
declare const webkitSpeechRecognition;

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {

    @Input() class = 'header-2 header-6';
    @Input() themeLogo = 'assets/images/loghi/pressUP/pressup_rosso.png';
    @Input() topbar = true;
    @Input() sticky = false;

    public stick: boolean;
    public isSearchingItem: boolean;
    public itemsStructure: Array<ItemStructure> = [];
    public selectedItemStructure: ItemStructure;
    public searchParams: string | undefined;
    public searchingCallback: () => Observable<void>;
    public initLoadItemCallback: () => Observable<Array<ItemStructure>>;
    public inputTextInSearch: string;
    private currentPage = 1;
    private totalPages: any;
    private readonly ITEM_PER_PAGE: number = 10;

    constructor(private pagesFacade: PagesFacade,
                private router: Router,
                private cdr: ChangeDetectorRef,
                private translate: TranslateService) {
    }

    ngOnInit(): void {
        this.searchingCallback = this.onSearch.bind(this);
        this.initLoadItemCallback = this.initLoad.bind(this);
    }

    @HostListener('window:scroll', [])
    onWindowScroll() {
        const hNumber = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
        this.stick = hNumber >= 150 && window.innerWidth > 400;
    }

    public onSelectedItem(item: ItemStructure): void {
        this.selectedItemStructure = item;
        const path: string = this.selectedItemStructure?.uri;
        this.router.navigate([path]);
    }

    private onSearch(): Observable<HttpResponse<Array<ItemStructure>>> {
        const searchParam: IPaginationParams = {
            termToSearch: this.searchParams,
            currentPage: this.currentPage,
            pageItems: this.ITEM_PER_PAGE
        };
        return this.pagesFacade.getSearchedItems(searchParam);
    }

    public changeVal(event: string): void {
        this.searchParams = event;
    }

    public responseApi(event: HttpResponse<Array<ItemStructure>>): void {
        this.totalPages = event?.headers?.get('Total-Pages');
        this.itemsStructure = event.body;
    }

    public clearOptions(): void {
        this.itemsStructure = [];
    }

    public goSearchPage() {
        if (this.searchParams?.length > 3) {
            this.clearOptions();
            this.selectedItemStructure = undefined;
            this.pagesFacade.setSearchTerms$(this.searchParams);
            this.router.navigate([`/search/${this.searchParams}`]);
            this.searchParams = undefined;
            this.cdr.detectChanges();
        }
    }

    public loadNextPage(): Observable<HttpResponse<Array<ItemStructure>>> {
        if (this.currentPage <= this.totalPages) {
            this.currentPage++;
            const searchParam: IPaginationParams = {
                termToSearch: this.searchParams,
                currentPage: this.currentPage,
                pageItems: this.ITEM_PER_PAGE
            }
            return this.pagesFacade.getSearchedItems(searchParam);
        } else {
            return of();
        }
    }

    public concatItem(event: HttpResponse<Array<ItemStructure>>): void {
        this.itemsStructure.concat(event.body);
    }

    public speechRecognitions(): void {
        if (this.isAvailableSpeechRecognitions()) {
            const vSearch = new webkitSpeechRecognition();
            vSearch.continuous = false;
            vSearch.interimresults = false;
            vSearch.lang = this.translate.currentLang;
            vSearch.start();
            vSearch.onresult = (e) => {
                const transcript = e?.results?.[0]?.[0]?.transcript;
                this.searchParams = transcript;
                this.inputTextInSearch = transcript;
                vSearch.stop();
            };
        } else {
            alert(this.translate.instant('HEADER.YOUR_BROWSER_NOT_SUPPORT_VOICE_RECOGNITION'));
        }
    }

    private isAvailableSpeechRecognitions(): boolean {
        return 'webkitSpeechRecognition' in window;
    }

    private initLoad(): Observable<Array<ItemStructure>> {
        return of([]);
    }
}
