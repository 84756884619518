<!--modal popup start-->
<ng-template class="theme-modal" #newsletter let-modal>
    <div class="modal-content">
        <div class="modal-body modal7">
            <div class="container-fluid p-0">
                <div class="row">
                    <div class="col-12">
                        <div class="modal-bg p-0">
                            <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div class="text-align-last-center pt-2">
                                <h2>{{'NEWSLETTER.PRESENT' | translate }}</h2>
                            </div>
                            <div class="offer-content">
                                <h1 class="text-align-last-center color-default">25 €</h1>
                                <h5 class="text-align-last-center text-uppercase font-bold">{{'NEWSLETTER.DISCOUNT_COUPON' | translate }}</h5>
                                <form action="https://pixelstrap.us19.list-manage.com/subscribe/post?u=5a128856334b598b395f1fc9b&amp;id=082f74cbda"
                                      class="auth-form needs-validation" method="post" id="mc-embedded-subscribe-form"
                                      name="mc-embedded-subscribe-form" target="_blank">
                                    <div class="form-group mx-sm-3">
                                        <button routerLink="/auth/registration" type="submit" class="btn btn-solid" id="mc-submit">subscribe</button>
                                    </div>
                                </form>
                                <h5 class="text-align-last-center text-uppercase font-bold mt-3 pb-2">{{'NEWSLETTER.REGISTER_AND_SUBSCRIBE' | translate }}</h5>
                            </div>
                            <div>
                                <input type="checkbox" (change)="dontEverShow()" class="me-2" id="showNL">{{'GENERAL.DONT_SHOW' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!--modal popup end-->