import {Component, OnInit} from '@angular/core';
import {Menu, TagItem} from '../../models/menu';
import {Root} from '../../models/MegaMenu';
import {PagesFacade} from '../../../pages/pages.facade';
import {Subscription, tap, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {BaseComponent} from '../../../core';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent extends BaseComponent implements OnInit {

    public menuItems: Menu[];
    public subscriptions: Array<Subscription> = [];
    public mainMenuToggle = false;
    private currentDate = new Date();
    private readonly BASE_URL = environment?.addressPressupBackoffice;

    constructor(private pagesFacade: PagesFacade,
                private router: Router,
                public translateService: TranslateService) {
        super(translateService);
    }

    ngOnInit(): void {
        this.getMenu();
    }

    mainMenuToggleAction(): void {
        this.mainMenuToggle = !this.mainMenuToggle;
    }

    // Click Toggle menu (Mobile)
    toggletNavActive(item) {
        item.active = !item.active;
    }

    public getTagForMenu(original: string | undefined) {
        return `${this.BASE_URL}${original}`;
    }
    // PRIVATE METHOD

    private getMenu(): void {
        const sb = this.pagesFacade.getMenu().pipe(
            tap((response: Root) => this.menuItems = Menu.buildMenuFullPageFromBO(response)),
            catchError(error => {
                console.error('Error get menu data => ', error);
                return throwError(() => error);
            })
        ).subscribe();
        this.subscriptions?.push(sb);
    }

    public goToDynamicPath(menuItem: Menu) {
        this.router.navigate([menuItem?.path]);
    }

    public checkTags(tagItem: TagItem): boolean {
        if (!tagItem?.to) {
            return tagItem?.from <= this.currentDate.toISOString();
        } else {
            return tagItem?.from <= this.currentDate.toISOString() && tagItem?.to >= this.currentDate.toISOString();
        }
    }
}
