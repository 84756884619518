<div class="icon-nav">
    <ul>
        <li class="show-div setting mobile-profile">
            <app-icon-profile-user></app-icon-profile-user>
        </li>
        <li class="onhover-div mobile-lang">
            <div>
                <app-language-selector></app-language-selector>
            </div>
        </li>
        <li class="onhover-div mobile-cart">
            <div>
                <a [routerLink]="['/shop/cart']">
                    <img src="assets/images/icon/menu/icn_cestino.svg" class="img-fluid dim-icon"  alt="">
                    <i class="ti-shopping-cart"></i>
                </a>
            </div>

            <span class="cart_qty_cls">{{ products.length }}</span>
            <ul class="show-div shopping-cart" *ngIf='!products.length'>
                <h5>Your cart is currently empty.</h5>
            </ul>
            <ul class="show-div shopping-cart" *ngIf='products.length'>
                <li *ngFor="let product of products">
                    <div class="media">
                        <a [routerLink]="['/shop/product/left/sidebar/', product?.title.replace(' ', '-')]">
                            <img class="me-3" [src]="product?.images[0]?.src" [alt]="product?.images[0]?.alt">
                        </a>
                        <div class="media-body">
                            <a [routerLink]="['/shop/product/left/sidebar/', product?.title.replace(' ', '-')]">
                                <h4>{{ product?.title }}</h4>
                            </a>
                            <h4>
                                <span>
                                  {{ product?.quantity }} x
                                    {{ product?.price * productService?.Currency.price | discount:product | currency:productService?.Currency.currency:'symbol' }}
                                </span>
                            </h4>
                        </div>
                    </div>
                    <div class="close-circle" (click)="removeItem(product)">
                        <a href="javascript:void(0)"><i class="fa fa-times" aria-hidden="true"></i></a>
                    </div>
                </li>
                <li>
                    <div class="total">
                        <h5>subtotal : <span>{{ getTotal | async | currency:productService?.Currency.currency:'symbol' }}</span></h5>
                    </div>
                </li>
                <li>
                    <div class="buttons">
                        <a [routerLink]="['/shop/cart']" class="view-cart">view cart</a>
                        <a [routerLink]="['/shop/checkout']" class="checkout">checkout</a>
                    </div>
                </li>
            </ul>
        </li>
    </ul>
</div>
