// Localization is based on '@ngx-translate/core';
// Please be familiar with official documentations first => https://github.com/ngx-translate/core

import {afterRender, Inject, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {DOCUMENT} from "@angular/common";

export interface Locale {
    lang: string;
    data: any;
}

const LOCALIZATION_LOCAL_STORAGE_KEY = 'language';

@Injectable({
    providedIn: 'root',
})
export class TranslationService {
    // Private properties
    private langIds: any = [];
    private languageCurrent$: BehaviorSubject<string>;
    private document: Document;

    constructor(@Inject(DOCUMENT) document: Document,
                private translate: TranslateService) {
        afterRender(() => {
            const selectedLanguage: string = this.getSelectedLanguage();
            // add new langIds to the list
            this.translate.addLangs(['it']);

            // this language will be used as a fallback when a translation isn't found in the current language
            this.translate.setDefaultLang('it');
            this.languageCurrent$ = new BehaviorSubject<string>('it');
            if (selectedLanguage) {
                this.setLanguage(selectedLanguage);
            }
        });
        this.document = document;
    }

    loadTranslations(...args: Locale[]): void {
        const locales = [...args];

        locales.forEach((locale) => {
            // use setTranslation() with the third argument set to true
            // to append translations instead of replacing them
            this.translate.setTranslation(locale.lang, locale.data, true);
            this.langIds.push(locale.lang);
        });

        // add new languages to the list
        this.translate.addLangs(this.langIds);
        this.translate.use(this.getSelectedLanguage());
    }

    setLanguage(lang: string) {
        if (lang) {
            this.translate.use(this.translate.getDefaultLang());
            this.translate.use(lang);
            this.document?.defaultView?.localStorage.setItem(LOCALIZATION_LOCAL_STORAGE_KEY, lang);
            document.documentElement.setAttribute('lang', lang);
            this.setLanguageCurrent(lang);
        }
    }

    /**
     * Returns selected language
     */
    getSelectedLanguage(): any {
        return (this.document?.defaultView?.localStorage?.getItem(LOCALIZATION_LOCAL_STORAGE_KEY) || this.translate.getDefaultLang());
    }

    get languageCurrentObservable$(): Observable<string> {
        return this.languageCurrent$.asObservable();
    }

    setLanguageCurrent(value: string): void {
        this.languageCurrent$.next(value);
    }

    getLanguageCurrentValue(): string {
        return this.languageCurrent$.value;
    }
}
