import {Component, OnInit, Input} from '@angular/core';
import {RegistrationFieldsEnum} from '../../core';
import {BaseComponent} from '../../core';
import {RegistrationModel} from '../../modules/auth/models/registration.model';
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends BaseComponent implements OnInit {
    @Input() class = 'footer-light'; // Default class
    @Input() themeLogo = 'assets/images/icon/logo.png'; // Default Logo
    @Input() newsletter = true; // Default True
    public emailToInsert: string;
    public registrationFields = RegistrationFieldsEnum;
    public registrationValues: RegistrationModel = new RegistrationModel();

    public today: Date = new Date();


   /* constructor(private pagesFacade: PagesFacade,
                private cdr: ChangeDetectorRef) {
        super();
    }*/

    constructor(public translateService: TranslateService) {
        super(translateService);
    }
    ngOnInit(): void {
        this.today.getFullYear();
    }

    /*public submit() {
        this.registrationValues.acceptPrivacyPolicy = this.registrationValues.acceptPrivacyPolicy ?? false;
        this.registrationValues.acceptTerms = this.registrationValues.acceptTerms ?? false;
        if (this.validForm(this.registrationValues, this.pagesFacade.checkRegistrationFields.bind(this.pagesFacade)) &&
            this.registrationValues.acceptPrivacyPolicy && this.registrationValues.acceptTerms) {
            const credentials: Credentials = new Credentials();
            credentials.email = this.registrationValues.email;
            this.pagesFacade.registration(credentials)
                .pipe(
                    tap(),
                    catchError((err) => {
                        this.managementErrorFields(err);
                        console.error('err', err);
                        this.cdr.detectChanges();
                        return throwError(() => err);
                    }),
                ).subscribe();
        }
    }*/

    public sendMail(): void {
        window.open('mailto:assistenza@pressup.it');
    }

    public chatOnSkype(): void {
        window.open('skype:pressup.it?chat', '_blank');
    }

    public sendTextMessageWA(): void {
        window.open('https://wa.me/393470845983?text=', '_blank');
    }
}
