const isFunction = (fn) => typeof fn === "function";
const doUnsubscribe = (subscription) => {
    subscription &&
        isFunction(subscription.unsubscribe) &&
        subscription.unsubscribe();
};
const doUnsubscribeIfArray = (subscriptionsArray) => {
    Array.isArray(subscriptionsArray) &&
        subscriptionsArray.forEach(doUnsubscribe);
};
function AutoUnsubscribe(param) {
    return function (constructor) {
        const event = param?.event ?? 'ngOnDestroy';
        // Save original event... (For example ngOnDestroy)
        const original = constructor.prototype[event];
        constructor.prototype[event] = function () {
            isFunction(original) && original.apply(this, arguments);
            if (param?.arrayName) {
                doUnsubscribeIfArray(this[param?.arrayName]);
                return;
            }
            for (let propName in this) {
                // @ts-ignore
                if (param?.blackList?.includes(propName))
                    continue;
                const property = this[propName];
                doUnsubscribe(property);
            }
        };
    };
}

/**
 * Generated bundle index. Do not edit.
 */
/// <amd-module name="ngx-ap-autounsubscribe" />

/**
 * Generated bundle index. Do not edit.
 */

export { AutoUnsubscribe };

