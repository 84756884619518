<div class="trustpilot-product pt-0">
    <div class="trustpilot-reviews-summary">
        <h6 class="trustpilot-product-name">
            {{'PAGES.TRUSTPILOT.REVIEWS_TITLE' | translate}}
        </h6>
        <img class="trustpilot-star-big" src="./assets/images/press-up/loghi/logo_trustpilot.svg">
        <div class="trustpilot-star-rating">
            <img *ngIf="startsImg" class="float-left" [src]="startsImg" alt="Rating" width="120" height="30" ngOptimizedImage>
            <div class="trustpilot-aggregate-rating">
                <span class="font-bold">
                        {{startsAverage}}/5
                </span>
                <span class="trustpilot-review-count">
                    (<span>{{(productReviewsSummary?.numberOfReviews?.fiveStars + productReviewsSummary?.numberOfReviews?.fourStars)}}</span><span>)</span>
                </span>
            </div>
        </div>
    </div>
    <div class="trustpilot-reviews-list">
        @for (review of reviews; track review) {
            <ng-template *ngTemplateOutlet="reviewTemplate; context: {$implicit: review}"></ng-template>
        }
        <div class="text-align-center py-3">
            <a (click)="goToNextPage()" class="cursor-hover color-trustpilot-green">{{'PAGES.TRUSTPILOT.SHOW_MORE_REVIEWS' | translate}}</a>
        </div>
    </div>
</div>

<ng-template #reviewTemplate let-review>
    <div class="trustpilot-review d-flex">
        <div class="w-25 float-left flex-column d-flex">
            <span class="mb-1" itemprop="author">
                {{review?.consumer?.displayName}}
            </span>
            <span class="trustpilot-review-date">
               {{review?.createdAt | date: 'dd/MM/YYYY'}}
            </span>
        </div>
        <div class="w-75 float-left flex-column d-flex h-100">
            <div class="mb-1">
                <img [src]="review?.starImage$ | async" width="100" height="20">
            </div>
            <span class="trustpilot-review-content" itemprop="description">
            {{review?.content}}
        </span>
        </div>
    </div>
</ng-template>
