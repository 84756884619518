<div class="main-navbar">
    <div id="mainnav">
        <div class="toggle-nav" (click)="mainMenuToggleAction()">
            <i class="fa fa-bars sidebar-bar"></i>
        </div>
        <ul class="nav-menu"
            style="justify-content: center !important;display: flex !important; align-items:center !important;"
            [class.opennav]="mainMenuToggle">

            <li class="back-btn">
                <div class="mobile-back text-end" (click)="mainMenuToggleAction()">
                    <span>Back</span>
                    <i class="fa fa-angle-right ps-2" aria-hidden="true"></i>
                </div>
            </li>

            <li *ngFor="let menuItem of menuItems" [class.mega-menu]="menuItem.megaMenu">
                <a class="nav-link clickable capitalize-first-letter" *ngIf="menuItem?.type === 'sub'"
                   (click)="toggletNavActive(menuItem)">
                    {{ getLabel(menuItem) }}
                    <div class="lable-nav" *ngIf="menuItem?.badge">{{ menuItem?.badgeText | translate }}</div>
                    <span class="sub-arrow" *ngIf="menuItem?.children"></span>
                </a>
                <a (click)="goToDynamicPath(menuItem)" routerLinkActive="active"
                   [routerLinkActiveOptions]="{exact: true}" class="nav-link clickable capitalize-first-letter"
                   *ngIf="menuItem?.type === 'link'">
                    {{ getLabel(menuItem) }}
                    <div class="lable-nav" *ngIf="menuItem?.badge">{{ menuItem?.badgeText | translate }}</div>
                    <span class="sub-arrow" *ngIf="menuItem?.children"></span>
                </a>
                <a href="{{ menuItem?.path }}" class="nav-link capitalize-first-letter" *ngIf="menuItem?.type === 'extLink'">
                    {{ getLabel(menuItem) }}
                    <div class="lable-nav" *ngIf="menuItem?.badge">{{ menuItem?.badgeText | translate }}</div>
                    <span class="sub-arrow" *ngIf="menuItem?.children"></span>
                </a>
                <a href="{{ menuItem?.path }}" class="nav-link capitalize-first-letter" *ngIf="menuItem?.type === 'extTabLink'">
                    {{ getLabel(menuItem) }}
                    <div class="lable-nav" *ngIf="menuItem?.badge">{{ menuItem.badgeText | translate }}</div>
                    <span class="sub-arrow" *ngIf="menuItem?.children"></span>
                </a>


                <div class="mega-menu-container" *ngIf="menuItem?.megaMenu">
                    <div>
                        <div class="row">
                            <div class="col mega-box" *ngFor="let subI of menuItem?.subItems">
                                <div class="link-section mb-2" style="border-top: solid lightgrey 2px"
                                     *ngFor="let childrenItem of subI">
                                    <div class="menu-title">
                                        <h5 (click)="goToDynamicPath(childrenItem)"
                                            class="color-default clickable mt-1 text-uppercase"
                                            *ngIf="childrenItem?.type === 'sub'">
                                            {{ getLabel(childrenItem) }}
                                            <span class="sub-arrow" *ngIf="childrenItem?.children"></span>
                                        </h5>
                                        <h5 (click)="goToDynamicPath(childrenItem)"
                                            class="color-default clickable mt-1 text-uppercase"
                                            *ngIf="childrenItem?.type === 'link'">
                                            {{ getLabel(childrenItem) }}
                                            <span class="sub-arrow" *ngIf="childrenItem?.children"></span>
                                        </h5>
                                    </div>
                                    <div class="menu-content opensubmegamenu">
                                        <ul *ngIf="childrenItem?.children">
                                            <li *ngFor="let childrenSubItem of childrenItem?.children">
                                                <a href="javascript:void(0)" *ngIf="childrenSubItem?.type === 'sub'">
                                                    {{ getLabel(childrenSubItem) }}
                                                    <span class="new-tag"
                                                          *ngIf="childrenSubItem.badge">{{ childrenSubItem?.badgeText | translate }}</span>
                                                </a>
                                                <a (click)="goToDynamicPath(childrenSubItem)" routerLinkActive="active"
                                                   class="clickable"
                                                   [routerLinkActiveOptions]="{exact: true}"
                                                   *ngIf="childrenSubItem?.type === 'link' ">
                                                    {{ getLabel(childrenSubItem) }}
                                                    <span class="new-tag ps-2 pe-2"
                                                          *ngIf="childrenSubItem?.badge">{{ childrenSubItem?.badgeText | translate }}</span>
                                                    <span *ngIf="childrenSubItem?.tag_items?.length > 0">
                                                        <span *ngFor="let menuTag of childrenSubItem?.tag_items">
                                                            @if (checkTags(menuTag)) {
                                                                <img class="ps-2 pe-2 default-size-tag img-fluid"
                                                                     ngSrc="{{menuTag?.icn_menu_path?.min}}" fetchpriority="auto" loading="lazy"
                                                                     alt="{{menuTag?.icn_menu_path?.originalname}}" width="32" height="32">
                                                            }
                                                        </span>
                                                    </span>
                                                </a>
                                                <a href="{{ childrenSubItem?.path }}"
                                                   *ngIf="childrenSubItem?.type === 'extLink' ">

                                                    {{ getLabel(childrenSubItem) }}
                                                    <span class="new-tag"
                                                          *ngIf="childrenSubItem?.badge">{{ childrenSubItem?.badgeText | translate }}</span>
                                                </a>
                                                <a href="{{ childrenSubItem?.path }}" target="_blank"
                                                   *ngIf="childrenSubItem?.type === 'extTabLink' ">
                                                    {{ getLabel(childrenSubItem) }}
                                                    <span class="new-tag"
                                                          *ngIf="childrenSubItem?.badge">{{ childrenSubItem?.badgeText | translate }}</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>
