export const environment = {
    production: true,
    mockResponse: true,
    stripe_token: 'STRIPE_TOKEN',
    addressPressupAPI: 'https://pressup-api.azurewebsites.net/',
    addressPressupAPIDEM: 'https://pressup-api-dem.azurewebsites.net/api/',
    addressPressupBackofficeAPI: 'https://pressup-api-bo-staging.democom.tech/api/',
    addressPressupBackoffice: 'https://pressup-api-bo-staging.democom.tech/',
    api_v1: 'v1',
    api_v2: 'v2',
    photonApi: 'https://photon.komoot.io/api/'
};
