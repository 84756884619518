<div *ngIf="isUserLogged(); else userNotLogged;" ngbDropdown class="cursor-hover" display="dynamic">
    <div ngbDropdownToggle class="kt-header__topbar-wrapper">
		<span class="kt-header__topbar-icon cursor-hover">
            @if (user?.userinfo?.name) {
                @if (responsiveOn) {
                    <ng-container *ngTemplateOutlet="responsiveView"></ng-container>
                } @else {
                    <span class="color-default" style="font-size: 14px"><b class="text-capitalize">{{ 'GENERAL.HELLO' | translate }}
                        ,&nbsp; {{ user?.userinfo?.name }}</b></span>&nbsp;
                }
            } @else {
                @if (responsiveOn) {
                    <ng-container *ngTemplateOutlet="responsiveView"></ng-container>
                } @else {
                    <span class="color-default" style="font-size: 14px"><b>{{ 'GENERAL.HELLO' | translate }}
                        ,&nbsp; {{ user?.username }}</b></span>&nbsp;
                }
            }
		</span>
    </div>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
         class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround responsive-dropdown-profile">
        <ul class="kt-nav kt-margin-t-10 kt-margin-b-10 p-0">
            <ng-container *ngFor="let item of items">
                <li ngbDropdownItem class="cursor-hover py-3"
                    [ngClass]="((tabSelected$ | async)?.value == item?.value) ? 'active-item' : ''"
                    (click)="goToProfilePage(item)">
                    <a>{{ item.label | translate }}</a>
                </li>
            </ng-container>
        </ul>
    </div>
</div>

<ng-template #userNotLogged>
    <img style="width: 23px" src="assets/images/icon/menu/icn_user.svg" class="img-fluid dim-icon cursor-hover"
         alt="user" (click)="goToLogin()">
</ng-template>


<ng-template #responsiveView>
    <img style="width: 23px" src="assets/images/icon/menu/icn_user.svg" class="img-fluid dim-icon cursor-hover"
         alt="user">
    <span class="user-logger-responsive"></span>
</ng-template>