<footer [class]="class">
    <div class="light-layout" *ngIf="newsletter">
        <div class="container">
            <section class="small-section border-section border-top-0" style="padding-bottom: 11px;">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="subscribe">
                            <div>
                                <h4 class="text-lowercase capitalize-first-letter">{{'AUTH.FOOTER.SUBSCRIBE_THE_NEWSLETTER' | translate}}</h4>
                                <h6> {{'AUTH.FOOTER.DATA_NOT_SEND' | translate}}</h6>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <form style="display: inline-flex" class="form-inline subscribe-form align-items-form"
                              novalidate="novalidate"
                              id="kt_subscribe_newsletter_form" (ngSubmit)="submit()">
                            <div class="form-group mx-xs-0 mx-sm-3">
                                <div class="input-margin-b-auth">
                                    <input style="margin-bottom: unset; border-radius: 10px" type="text"
                                           class="form-control" id="email"
                                           name="email"
                                           [(ngModel)]="registrationValues.email"
                                           placeholder="{{'AUTH.REGISTER.EMAIL' | translate}}" required #email="ngModel"
                                           (keyup)="resetFieldValidation(registrationFields.EMAIL)"
                                           autocomplete="off"
                                           [ngClass]="isEmptyErrorByField(registrationFields.EMAIL)  ? '' : 'input-error-text'"/>
                                    <small class="p-error" [hidden]="isEmptyErrorByField(registrationFields.EMAIL)">
                                        <span *ngIf="!isEmptyErrorByField(registrationFields.EMAIL)">{{getFirstErrorByField(registrationFields.EMAIL) | translate : {name: 'AUTH.INPUT.EMAIL' | translate} }}</span>
                                    </small>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-solid min-w-120px">{{'GENERAL.SUBSCRIBE' | translate}}</button>
                        </form>
                    </div>
                </div>
                <div class="row">
                    <div class="offset-lg-6">
                        <div style="margin-left: 1rem; margin-top: 0.5rem;">
                            <input type="checkbox" class="me-2" name="acceptPrivacyPolicy" value="privacyPolicyAccepted"
                                   [(ngModel)]="registrationValues.acceptPrivacyPolicy"
                                   [ngClass]="registrationValues.acceptPrivacyPolicy === false ? 'input-error-checkbox' : ''">
                            <span class="text-muted">{{'AUTH.FOOTER.ACCEPT_PRIVACY' | translate}}</span>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <section class="section-b-space light-layout">
        <div class="container">
            <div class="row footer-theme partition-f">
                <div class="col-lg-4 col-md-6">
                    <div class="footer-title footer-mobile-title">
                        <h4>about</h4>
                    </div>
                    <div class="footer-contant">
                        <div class="footer-logo">
                            <img src="assets/images/loghi/pressUP/pressup_rosso.svg" alt="logo pressup">
                        </div>
                        <p style="color: #777777;">{{'AUTH.FOOTER.VAT_NUMBER_FISCAL_CODE' | translate}}</p>
                        <p style="color: #777777;">IT10922761001</p>
                        <div class="footer-social">
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/Pressup.it" target="_blank">
                                        <img style="width: 20px;" src="assets/images/social/facebook.svg"
                                             alt="logo facebook">
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/pressUp_it" class="social_link twitter"
                                       target="_blank">
                                        <img style="width: 20px;" src="assets/images/social/twitter.svg"
                                             alt="logo twitter">
                                    </a>
                                </li>
                                <li>
                                    <a href="http://www.youtube.com/pressup" class="social_link youtube"
                                       target="_blank">
                                        <img style="width: 20px;" src="assets/images/social/youtube.svg"
                                             alt="logo youtube">
                                    </a>
                                </li>
                                <li>
                                    <a href="http://it.pinterest.com/pressup/" class="social_link pinterest"
                                       target="_blank">
                                        <img style="width: 20px;" src="assets/images/social/pinterest.svg"
                                             alt="logo pinterest">
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col offset-xl-1">
                    <div class="sub-title">
                        <div class="footer-title">
                            <h6 class="text-lowercase capitalize-first-letter color-black fw-600">{{'AUTH.FOOTER.SHIPMENTS' | translate}}</h6>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li>
                                    <a>
                                        <img style="width: 50px;" src="assets/images/loghi/bartolini.svg"
                                             alt="logo bartolini">
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="sub-title">
                        <div class="footer-title">
                            <h6 class="text-lowercase capitalize-first-letter color-black fw-600">{{'AUTH.FOOTER.PRINT' | translate}}</h6>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li class="mt-2 pt-0 clickable text-transform-none">
                                    <p routerLink="/printing-techniques">
                                        {{'AUTH.FOOTER.PRINTING_TECHNIQUES' | translate}}
                                    </p>
                                </li>
                                <li class="pt-0 clickable text-transform-none">
                                    <p routerLink="/sustainable-printing">
                                        {{'AUTH.FOOTER.SUSTAINABLE_PRINTING' | translate}}
                                    </p>
                                </li>
                                <li class="pt-0 cursor-hover text-transform-none">
                                    <p routerLink="/guidelines">
                                        {{'AUTH.FOOTER.FILE_GUIDELINES' | translate}}
                                    </p>
                                </li>
                                <li class="pt-0 clickable text-transform-none">
                                    <p routerLink="/faq">
                                        FAQ
                                    </p>
                                </li>
                                <li>
                                    <img style="width: 50px;" src="assets/images/loghi/fsc.svg" alt="logo phone"> &nbsp;
                                    <img style="width: 50px;" src="assets/images/loghi/pefc.svg" alt="logo phone">
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="sub-title">
                        <div class="footer-title">
                            <h6 class="text-lowercase capitalize-first-letter color-black fw-600">Policy</h6>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li class="pt-0 cursor-hover text-transform-none">
                                    <p routerLink="/cookie-policy">
                                        Cookie
                                    </p>
                                </li>
                                <li class="pt-0 cursor-hover text-transform-none">
                                    <p routerLink="/privacy">
                                        Privacy
                                    </p>
                                </li>
                                <li class="pt-0 cursor-hover text-transform-none">
                                    <p routerLink="/general_conditions_of_sale">
                                        {{'AUTH.FOOTER.GENERAL_CONDITIONS_OF_SALE' | translate}}
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="sub-title">
                        <div class="footer-title">
                            <h6 class="cursor-hover text-lowercase capitalize-first-letter color-black fw-600" routerLink="/contact">{{'AUTH.FOOTER.CONTACTS' | translate}}</h6>
                        </div>
                        <div class="footer-contant">
                            <ul class="contact-list">
                                <li class="pt-0 ps-0 cursor-hover text-transform-none">
                                    <p routerLink="/aboutus">
                                        {{'AUTH.FOOTER.ABOUT_US' | translate}}
                                    </p>
                                </li>
                                <li class="pt-0 ps-0 cursor-hover text-transform-none">
                                    <p routerLink="/blog/left/sidebar">
                                        Blog
                                    </p>
                                </li>
                                <li class="pt-0 ps-0 d-flex">
                                    <img style="width: 15px;" src="assets/images/social/telefono.svg" alt="logo phone">
                                    <p style="margin-top: 5px; margin-left: 10px">
                                        076-15.21.351
                                    </p>
                                </li>
                                <li class="pt-0 ps-0 d-flex text-transform-none">
                                    <img style="width: 15px;" src="assets/images/social/mail.svg" alt="logo mail">
                                    <p (click)="sendMail()" href="mailto:assistenza@pressup.it" style="margin-top: 5px; margin-left: 10px"
                                        class="clickable info_text">
                                        assistenza&#64;pressup.it
                                    </p>
                                </li>
                                <li class="pt-0 ps-0 d-flex">
                                    <img style="width: 15px;" src="assets/images/social/skype.svg" alt="logo skype">
                                    <p (click)="chatOnSkype()" class="clickable" style="margin-top: 5px; margin-left: 10px">
                                        Skype
                                    </p>
                                </li>
                                <li class="pt-0 ps-0 d-flex">
                                    <img style="width: 15px;" src="assets/images/social/whatapp.svg" alt="logo whatsapp">
                                    <p (click)="sendTextMessageWA()" class="social_link whatsapp clickable"
                                       style="margin-top: 5px; margin-left: 10px"
                                       onclick="fbq('track', 'Lead', {content_name: 'contatto-whatsapp'});ga('send', 'event', 'contatto-whatsapp', 'click')">
                                        WhatsApp
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="sub-footer">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="footer-end">
                        <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today?.getFullYear() }} PressUP</p>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="payment-card-bottom">
                        <ul>
                            <li>
                                <a><img width="45" src="assets/images/metodi_di_pagamento/visa.svg" alt="visa logo"></a>
                            </li>
                            <li>
                                <a><img width="45" src="assets/images/metodi_di_pagamento/american_express.svg"
                                        alt="American express logo"></a>
                            </li>
                            <li>
                                <a><img width="45" src="assets/images/metodi_di_pagamento/maestro.svg"
                                        alt="Maestro logo"></a>
                            </li>
                            <li>
                                <a><img width="45" src="assets/images/metodi_di_pagamento/paypal.svg" alt="Paypal logo"></a>
                            </li>
                            <li>
                                <a><img width="45" src="assets/images/metodi_di_pagamento/union_pay.svg"
                                        alt="Union pay logo"></a>
                            </li>
                            <li>
                                <a><img width="45" src="assets/images/metodi_di_pagamento/pago_bancomat.svg"
                                        alt="Bancomat logo"></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
