import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ItemStructure} from '../models/item-structure.model';

@Injectable({
    providedIn: 'root'
})
export class DynamicLoaderService {

    //   private readonly ENDPOINT_V1: string = `${environment.addressPressupBackofficeAPI}${environment.api_v1}`;
    private readonly ENDPOINT_V2: string = `${environment.addressPressupBackofficeAPI}${environment.api_v2}`;

    constructor(private http: HttpClient) {
    }

    //   MOMENTANEAMENTE NON IN USO
    /*public getAllMinimalStructureList(): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${this.ENDPOINT_V1}/menu-item/index?isMinimal=true`);
    }

    public getItemStructureById(id: string): Observable<ItemStructure> {
        return this.http.get<ItemStructure>(`${this.ENDPOINT_V1}/menu-item/${id}`);
    }

    public getGenericItem(root_id: string, level: number, id: string): Observable<ItemStructure> {
        return this.http.get<ItemStructure>(`${this.ENDPOINT_V1}/menu-item/root_id/${root_id}/level/${level}/target_id/${id}`);
    }*/

    public getGenericItemByUri(uri: string): Observable<ItemStructure> {
        return this.http.get<ItemStructure>(`${this.ENDPOINT_V2}/menu-item/uri?uri=${uri}`);
    }

    public getRelatedProduct(terms: { terms: string[]; }): Observable<Array<ItemStructure>> {
        return this.http.post<Array<ItemStructure>>(`${this.ENDPOINT_V2}/product/related-products-by-criteria`, terms);
    }
}
