<div class="breadcrumb-section">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <nav aria-label="breadcrumb" class="theme-breadcrumb">
                    <ol class="breadcrumb justify-content-start text-lowercase">
                        <li *ngFor="let item of itemsBreadcrumb; let last = last" class="breadcrumb-item capitalize-first-letter">
                            <a [ngStyle]="{'color': last ? 'gray' : 'var(--theme-deafult)'}"
                               [ngClass]="item?.path != '' ? 'clickable': ''"
                               (click)="goToPageSelect(item?.path)">{{ item?.label | translate }}</a>
                        </li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
