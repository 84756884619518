import {
    AfterViewInit,
    ChangeDetectorRef, Component, EventEmitter, HostListener,
    Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {defer, EMPTY, fromEvent, Observable, of, Subscription, throwError} from 'rxjs';
import {catchError, debounceTime, finalize, map, switchMap, tap} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {NgSelectComponent} from '@ng-select/ng-select';
import * as objectPath from 'object-path';

@Component({
    selector: 'app-ng-select',
    templateUrl: './ng-select-custom.component.html',
    styleUrls: ['./ng-select-custom.component.scss']
})
export class NgSelectCustomComponent implements OnInit, OnChanges, AfterViewInit {

    @ViewChild('term', {static: true, read: NgSelectComponent}) public inputSelect: NgSelectComponent;

    @Input() notFound = 'GENERAL.NOT_FOUND';
    @Input() placeholder: string;
    @Input() virtualScroll = true;
    @Input() bindLabel: string;
    @Input() customLabel = false;
    @Input() bindValue: string;
    @Input() selectId: string;
    @Input() items: any[] = [];
    @Input() loading = true;
    @Input() loadingText = 'Caricamento...';
    @Input() selectedItem: any;
    @Input() name: string;
    @Input() scrollToEndCallback: () => Observable<any>;
    @Input() searchingCallback: () => Observable<any>;
    @Input() initLoadItems: () => Observable<any>;
    @Input() searchExistingItemCallback: () => Observable<any>;
    @Input() onKeyEnterPressed: () => void;
    @Input() inputText: string;
    @Input() dropdownType: string;
    @Input() required = false;
    @Input() multiple = false;
    @Input() clearable = true;
    @Input() errorStyle = false;
    @Input() isStaticItem = false;
    @Input() disabled = false;
    @Input() addTag = false;
    @Input() hideDropdownIfEmptyOptions = false;
    @Input() returnFullObjectOnChanged = false;
    @Input() clearInputAfterSelectItem = false;
    @Input() customClass: Array<string> | string;
    @Input() addTagText: string;
    @Input() headerTemplate: TemplateRef<any>;
    @Input() itemTemplate: TemplateRef<any>;
    @Input() isOptionLabelToTranslate: boolean;
    @Input() minCharForSearch = 1;

    @Output() resetEvent = new EventEmitter<boolean>();
    @Output() scrollToEndEvent = new EventEmitter<any>();
    @Output() searchingParamsEvent = new EventEmitter<any>();
    @Output() searchingEvent = new EventEmitter<any>();
    @Output() loadItemsEvent = new EventEmitter<any>();
    @Output() searchExistingItemEvent = new EventEmitter<any>();
    @Output() selectedItemChangeEvent = new EventEmitter<any>();
    @Output() focusEvent = new EventEmitter<boolean>();
    @Output() openEvent = new EventEmitter<boolean>();
    @Output() closeEvent = new EventEmitter<boolean>();

    subscriptions: Subscription[] = [];
    selectedItemOrManuallyInserted: any;
    isFocused: boolean;

    constructor(public translate: TranslateService,
                private cdr: ChangeDetectorRef) {
    }

    @HostListener('document:keydown.enter')
    handlerKeyboardPressed() {
        if (this.onKeyEnterPressed) {
            this.onKeyEnterPressed();
        }
    }

    ngOnInit() {
        if (!this.isStaticItem) {
            this.loadItems();
            this.search();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        this.notFound = changes?.notFound?.currentValue ?? this.notFound;
        this.placeholder = changes?.placeholder?.currentValue ?? this.placeholder;
        this.virtualScroll = changes?.virtualScroll?.currentValue ?? this.virtualScroll;
        this.bindLabel = changes?.bindLabel?.currentValue ?? this.bindLabel;
        this.customLabel = changes?.customLabel?.currentValue ?? this.customLabel;
        this.bindValue = changes?.bindValue?.currentValue ?? this.bindValue;
        this.selectId = changes?.selectId?.currentValue ?? this.selectId;
        this.items = changes?.items?.currentValue ?? this.items;
        this.loading = changes?.loading?.currentValue ?? this.loading;
        this.loadingText = changes?.loadingText?.currentValue ?? this.loadingText;
        this.selectedItem = changes?.selectedItem ? changes?.selectedItem?.currentValue : this.selectedItem;
        this.name = changes?.name?.currentValue ?? this.name;
        this.scrollToEndCallback = changes?.scrollToEndCallback?.currentValue ?? this.scrollToEndCallback;
        this.searchingCallback = changes?.searchingCallback?.currentValue ?? this.searchingCallback;
        this.initLoadItems = changes?.initLoadItems?.currentValue ?? this.initLoadItems;
        this.searchExistingItemCallback = changes?.searchExistingItemCallback?.currentValue ?? this.searchExistingItemCallback;
        this.onKeyEnterPressed = changes?.onKeyEnterPressed?.currentValue ?? this.onKeyEnterPressed;
        this.inputText = changes?.inputText?.currentValue ?? this.inputText;
        this.clearable = changes?.clearable?.currentValue ?? this.clearable;
        this.errorStyle = changes?.errorStyle?.currentValue ?? this.errorStyle;
        this.customClass = changes?.customClass?.currentValue ?? this.customClass;
        this.isStaticItem = changes?.isStaticItem?.currentValue ?? this.isStaticItem;
        this.disabled = changes?.disabled?.currentValue ?? this.disabled;
        this.addTag = changes?.addTag?.currentValue ?? this.addTag;
        this.hideDropdownIfEmptyOptions = changes?.hideDropdownIfEmptyOptions?.currentValue ?? this.hideDropdownIfEmptyOptions;
        this.clearInputAfterSelectItem = changes?.clearInputAfterSelectItem?.currentValue ?? this.clearInputAfterSelectItem;
        this.addTagText = changes?.addTagText?.currentValue ?? this.addTagText;
        this.headerTemplate = changes?.headerTemplate?.currentValue ?? this.headerTemplate;
        this.itemTemplate = changes?.itemTemplate?.currentValue ?? this.itemTemplate;
        this.selectedItemOrManuallyInserted = this.inputSelect.selectedItems[0]?.value ?? this.selectedItemOrManuallyInserted;
        this.returnFullObjectOnChanged = changes?.returnFullObjectOnChanged?.currentValue ?? this.returnFullObjectOnChanged;
        this.isOptionLabelToTranslate = changes?.isoptionLabelToTranslate?.currentValue ?? this.isOptionLabelToTranslate;

        if (changes?.inputText?.currentValue) {
            this.setTextInInputSelect();
        }
    }

    ngAfterViewInit() {
        this.inputSelect.searchInput.nativeElement.addEventListener('focus', () => {
            this.isFocused = true;
        });
        this.inputSelect.searchInput.nativeElement.addEventListener('blur', () => {
            this.isFocused = false;
            this.focusEvent.emit(false);
        });
    }

    public resetList(): void {
        // this.loadItems();
        this.selectedItemOrManuallyInserted = undefined;
        this.resetEvent.emit(true);
    }

    public loadItems(): void {
        if (!!this.initLoadItems) {
            this.loading = true;
            const sb = this.getInitLoadItemsCallback().subscribe();
            this.subscriptions.push(sb);
        }
    }

    public scrollToEnd(): void {
        if (!!this.scrollToEndCallback) {
            this.loading = true;
            const sb = this.getScrollToEndCallback().subscribe();
            this.subscriptions.push(sb);
        }
    }

    public search(): void {
        const sb = fromEvent(this.inputSelect.element, 'keyup').pipe(
            map((event: any) => {
                this.searchingParamsEvent.emit(event.target.value);
                return event.target.value;
            }),
            debounceTime(500),
            switchMap(text => {
                if (!this.searchingCallback) {
                    return of({});
                }
                if (text?.length > this.minCharForSearch) {
                    this.loading = true;
                    this.cdr.detectChanges();
                    return this.getSearchingItemsCallback();
                }
                return this.getInitLoadItemsCallback();
            })
        ).subscribe();
        this.subscriptions.push(sb);
    }

    public selectedItemChange(event: any): void {
        this.selectedItem = event?.[this.bindValue];
        this.selectedItemOrManuallyInserted = event;
        this.selectedItemChangeEvent.emit(this.returnFullObjectOnChanged ? event : this.selectedItem);
        if (this.clearInputAfterSelectItem) {
            this.selectedItem = undefined;
            this.cdr.detectChanges();
            this.inputSelect.blur();
        }
    }

    public onFocus(): void {
        this.focusEvent.emit(true);
    }

    public onOpen(): void {
        this.openEvent.emit(true);
    }

    public onClose(): void {
        this.closeEvent.emit(true);
    }

    public getLabel(item: any): string {
        const label = item && objectPath.get(item, this.bindLabel);
        return this.isOptionLabelToTranslate ? this.translate.instant(label) : label;
    }

    private getExistingItem(): Observable<any> {
        return defer(() => {
            if (this.canSearchItem()) {
                this.loading = true;
                return this.searchExistingItemCallback().pipe(
                    tap((data: any) => {
                        if (data) {
                            this.searchExistingItemEvent?.emit(data);
                            this.cdr.detectChanges();
                        }
                    }),
                    catchError(err => {
                        console.error(err);
                        return of({});
                    }),
                    finalize(() => this.loading = false));
            } else {
                return EMPTY;
            }
        });
    }

    private canSearchItem(): boolean {
        const isElementsAlreadyPresent = (this.selectedItem instanceof Array) ? this.selectedItem.every(el => this.items.includes(el)) : this.items.includes(this.selectedItem);
        return (this.selectedItem &&
            !!this.searchExistingItemCallback &&
            this.searchExistingItemEvent &&
            !isElementsAlreadyPresent);
    }

    private getInitLoadItemsCallback(): Observable<any> {
        return this.initLoadItems().pipe(
            tap((data: HttpResponse<any>) => {
                this.loading = false;
                this.loadItemsEvent.emit(data);
            }),
            switchMap(() => this.getExistingItem()),
            catchError(error => {
                this.loading = false;
                return throwError(() => error);
            }),
        );
    }

    private getScrollToEndCallback(): Observable<HttpResponse<any>> {
        return this.scrollToEndCallback().pipe(
            tap((data: HttpResponse<any>) => {
                this.loading = false;
                this.scrollToEndEvent?.emit(data);
            }),
            catchError((err) => {
                this.loading = false;
                console.error(err);
                return of(null);
            })
        );
    }

    private getSearchingItemsCallback(): Observable<any> {
        return this.searchingCallback().pipe(
            tap((response) => {
                this.loading = false;
                this.cdr.detectChanges();
                this.searchingEvent.emit(response);
            }),
            catchError((error) => {
                this.loading = false;
                console.error(error);
                return throwError(() => error);
            })
        );
    }

    private setTextInInputSelect(): void {
        this.inputSelect.writeValue(this.inputText);
        this.inputSelect.searchInput.nativeElement.value = this.inputText;
        (this.inputSelect.element as HTMLDataElement).value = this.inputText;
        this.inputSelect.element.dispatchEvent(new Event('keyup'));
    }
}
