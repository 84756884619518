import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {PagesFacade} from '../../../pages/pages.facade';
import {Subscription, switchMap, tap, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {
    IProductSku,
    IStars,
    ITrustpilotProductReviewsSummary,
    ITrustpilotReview
} from '../../models/interfaces/ITrustpilot';
import {BaseComponent} from '../../../core';
import {TranslateService} from '@ngx-translate/core';
import {IPaginationParams} from '../../interfaces/IPaginationParams';
import {TrustpilotProductReviewResponse} from '../../models/trustpilotProductReview.model';
import {AutoUnsubscribe} from 'ngx-ap-autounsubscribe';

@AutoUnsubscribe({arrayName: 'subscriptions'})
@Component({
    selector: 'app-trustbox-product-reviews',
    templateUrl: './trustbox-product-reviews.component.html',
    styleUrls: ['./trustbox-product-reviews.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrustboxProductReviewsComponent extends BaseComponent implements OnInit {

    public reviews: Array<ITrustpilotReview> = [];
    public productReviewsSummary: ITrustpilotProductReviewsSummary;
    public startsAverage: string;
    public startsImg: string;
    public subscriptions: Array<Subscription> = [];

    private sku: Array<string> = [];
    private allSku: Array<IProductSku> = [];

    constructor(private pageFacade: PagesFacade,
                public translate: TranslateService) {
        super(translate);
    }

    ngOnInit(): void {
        this.currentPage = 1;
        this.pageItems = 10;
        this.getSkuList();
    }

    public goToNextPage(): void {
        this.currentPage++;
        this.getTrustpilotReviews();
    }

    // Private methods
    private getTrustpilotProductReviewsSummary(): void {
        const sub = this.pageFacade.getTrustpilotProductReviewsSummary(this.sku).pipe(
            switchMap((productReviewsSummary: ITrustpilotProductReviewsSummary) => {
                this.productReviewsSummary = productReviewsSummary;
                const starAverage = this.calcStarsAverage(productReviewsSummary);
                this.startsAverage = starAverage;
                const starRounded = this.roundToHalf(parseFloat(starAverage));
                return this.pageFacade.getTrustpilotStarImageResources(starRounded);
            }),
            tap((response: IStars) => this.startsImg = response?.starSvg?.url),
            catchError(err => {
                console.error('Error retrieve trustpilot data');
                return throwError(() => err);
            })
        ).subscribe();
        this.subscriptions.push(sub);
    }

    private getTrustpilotReviews(): void {
        const paginationParams: IPaginationParams = {
            currentPage: this.currentPage,
            pageItems: this.pageItems
        };

        const sub = this.pageFacade.getTrustpilotReviews(paginationParams, this.sku).pipe(
            tap((data: TrustpilotProductReviewResponse) => {
                const mappedReviews = this.getStarsImage(data?.productReviews ?? []);
                this.reviews = this.reviews.concat(mappedReviews);
            }),
            catchError(err => {
                console.error('Error retrieve trustpilot reviews data');
                return throwError(() => err);
            })
        ).subscribe();
        this.subscriptions.push(sub);

    }

    private getStarsImage(reviews: Array<ITrustpilotReview>): Array<ITrustpilotReview> {
        return reviews.map(review => {
            review.starImage$ = this.pageFacade.getTrustpilotStarImageResources(review?.stars).pipe(
                map((response: IStars) => response?.starSvg?.url)
            );
            return review;
        });
    }

    private calcStarsAverage(productReviewsSummary: ITrustpilotProductReviewsSummary): string {
        const average = ((productReviewsSummary.numberOfReviews.fiveStars * 5) + (productReviewsSummary.numberOfReviews.fourStars * 4)) /
            (productReviewsSummary.numberOfReviews.fourStars + productReviewsSummary.numberOfReviews.fiveStars);
        return average.toFixed(1);
    }

    private roundToHalf(num: number): number {
        return Math.round(num * 2) / 2;
    }

    private getSkuList(): void {
        const sub = this.pageFacade.skuList().pipe(
            tap(skuList => {
                if (skuList?.length > 0) {
                    this.allSku = skuList;
                    this.sku = this.allSku.map(sku => sku.SKU);
                    this.getTrustpilotReviews();
                    this.getTrustpilotProductReviewsSummary();
                }
            }),
        ).subscribe();
        this.subscriptions.push(sub);
    }
}
