import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {IProduct} from '../../../pages/press-up-now/press-up-now.component';
import {BaseComponent} from '../../../core';
import {Configuration} from '../../../pages/home/models/configuration.model';
import {environment} from '../../../../environments/environment';
import {ItemStructure} from '../../dynamic-loader/models/item-structure.model';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-product-card-three-dimensional',
    templateUrl: './product-card-three-dimensional.component.html',
    styleUrls: ['./product-card-three-dimensional.component.scss']
})
export class ProductCardThreeDimensionalComponent extends BaseComponent implements OnChanges {

    @Input() products: IProduct[];
    @Input() product: IProduct;
    //  home
    @Input() productConf: Configuration;
    // showcase
    @Input() item: ItemStructure;

    private readonly BASE_URL = environment?.addressPressupBackoffice;

    constructor(public translateService: TranslateService,
                private router: Router) {
        super(translateService);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.products = changes?.products?.currentValue ?? this.products;
        this.product = changes?.product?.currentValue ?? this.product;
        this.productConf = changes?.productConf?.currentValue ?? this.productConf;
        this.item = changes?.item?.currentValue ?? this.item;
    }

    public getImg(original: string | undefined) {
        return `${this.BASE_URL}${original}`;
    }

    public goToLink(uri: string) {
        this.router.navigate([`/${uri}`]);
    }
}
