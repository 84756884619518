import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {SkeletonType} from "../../enum/skeleton-type";

@Component({
  selector: 'app-skeleton-builder',
  templateUrl: './skeleton-builder.component.html',
  styleUrl: './skeleton-builder.component.scss'
})
export class SkeletonBuilderComponent implements OnInit, OnChanges {

  @Input() skeleton: string;
  @Input() height: string;
  @Input() col: string;
  @Input() numOfElement: number;
  @Input() centerTxt: boolean;
  @Input() padding_bottom: string;

  public fakeSpinner: Array<number>;
  protected readonly SkeletonType = SkeletonType;
  ngOnChanges(changes: SimpleChanges): void {
    this.skeleton = changes?.skeleton?.currentValue ?? this.skeleton;
    this.height = changes?.height?.currentValue ?? this.height;
    this.col = changes?.col?.currentValue ?? this.col;
    this.numOfElement = changes?.numOfElement?.currentValue ?? this.numOfElement;
    this.centerTxt = changes?.centerTxt?.currentValue ?? this.centerTxt;
    this.padding_bottom = changes?.padding_bottom?.currentValue ?? this.padding_bottom;
  }

  ngOnInit(): void {
    this.fakeSpinner = new Array(this.numOfElement);
  }
}
